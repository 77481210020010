import "./style.css";
import { Link } from "react-router-dom";
import { News, Slider } from "../../components";
import { default as ImagesSlider } from "./ImagesSlider.json";
import { default as documentFolder } from "./documents.json";
import Collapsible from "react-collapsible";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useCountdown } from "../../hooks/useCountdown";
import React, { useState } from "react";
import Announcement from "../../components/Announcement";

const DateTimeDisplay = ({
  value,
  type,
  isDanger,
}: {
  value: number;
  type: any;
  isDanger: boolean;
}) => {
  return (
    <div className={isDanger ? "countdown danger" : "countdown"}>
      <p>{value}</p>
      <span>{type}</span>
    </div>
  );
};

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return (
    <div className="show-counter">
      <a
        href=""
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link"
      >
        <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
      </a>
    </div>
  );
};

const CountdownTimer = ({ targetDate }: { targetDate: number }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    // setShowTimer(false);
    return <></>;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default function Homepage(): JSX.Element {
  const forumDate = new Date(2024, 10, 2).getTime();
  const surveyDate = new Date("10/31/2022").getTime();
  const NOW_IN_MS = new Date().getTime();
  const DIFFERENCE_DAYS_IN_MS = forumDate - NOW_IN_MS;
  const dateTimeAfterThreeDays = NOW_IN_MS + DIFFERENCE_DAYS_IN_MS;
  const [documents] = useState(documentFolder.data);
  const [viewDocuments, setViewDocument] = useState(false);
  const [selectTranslation, setSelectTranslation] = useState(-1);

  function CollapsibleTrigger(
    titleOnOpen: string,
    titleOnClose: string,
    isOpen: boolean
  ): JSX.Element {
    return (
      <div className="collapsible-trigger">
        <h3>{isOpen ? titleOnOpen : titleOnClose}</h3>
        {isOpen ? (
          <ExpandLessIcon fontSize="large" />
        ) : (
          <ExpandMoreIcon fontSize="large" />
        )}
      </div>
    );
  }

  return (
    <div className="home-page-container">
      {/* Forum Information */}
      <div className="upcoming-event">
        <Slider slides={ImagesSlider.data} />
        {/* <h1>Save the Date</h1> */}

        {DIFFERENCE_DAYS_IN_MS > 0 && (
          <div>
            <h2>Register Today!!</h2>
            <CountdownTimer targetDate={dateTimeAfterThreeDays} />
          </div>
        )}
        {/* {DIFFERENCE_DAYS_IN_MS < 0 && surveyDate - NOW_IN_MS > 0 && (
          <div>
            <h2>Registration is Closed!!</h2>
            <h4>
              Thank you for participating, Please complete the Survey by October
              30, 2022
            </h4>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSf-ZJ2mDjxBylJuTfJlgZbdNHJXtOxLvkVcYL0W3_kRynkYxA/viewform?usp=sf_link"
              target="_blank"
            >
              <div className="survey-button">
                <h3>Complete the Survey</h3>
              </div>
            </a>
          </div>
        )} */}

        <a href="/register">
          <img
            src="https://raw.githubusercontent.com/101Ben/YLFContent/master/2024_Docs/2024_Flyer_2.png"
            alt="2024 Save the date"
          />
        </a>
        {/* <div className="news-and-article">
          <br />
          <h1>Community Events and Activities</h1>
          <Announcement />
        </div> */}
        {/* {documents.map((file: any, index: number) => {
          var open: boolean = viewDocuments && selectTranslation == index;
          return (
            <Collapsible
              trigger={CollapsibleTrigger(file.title, file.title, open)}
              open={open}
              onTriggerOpening={() => {
                setViewDocument(true), setSelectTranslation(index);
              }}
              onTriggerClosing={() => {
                setViewDocument(false), setSelectTranslation(-1);
              }}
            >
              <img src={file.imageLink} alt={file.title} />
            </Collapsible>
          );
        })} */}
        {/* <div className="upcoming-event-moreinfo">
          <h2>More information about the Event</h2>

          <h4>
            Tukwila Community Center: <br />
            <a
              href="https://www.tukwilawa.gov/departments/parks-and-recreation/parks-and-trails/tukwila-community-center/"
              target="_blank"
            >
              {` Learn more about the center here.`}
            </a>
          </h4>
          <h4>
            Wondering about ways to get there?
            <br />
            <a href="https://forms.gle/aXC7ab1Bs3p8vc538" target="_blank">
              {` Sign up for transportation ride.`}
            </a>
          </h4>
          <h4>
            What are the prizes for this year? <br />
            <a href="/forum/prizes">{` Check the prizes here.`}</a>
          </h4>
          <h4>
            Schedules and Speakers info can be found the on the
            <a href="/forum">{` Forum page.`}</a>
          </h4>
        </div> */}
        {/* <a href="/forum">
          <img
            src="https://raw.githubusercontent.com/YouthandLaw/YLFContent/master/second-flyer-2022.jpg"
            alt="2022 flyer"
          />
        </a> */}
        <div className="scholarship-announce">
          <h3>
            Scholarship applications are closed. Winners were awarded on June
            25, 2023
            <b></b>
            <br />
            <Link to="/scholarship">More information Here</Link>
          </h3>
        </div>

        <br />
      </div>

      <div className="pastor-dedication">
        <h1>
          Dedicated to Co-Founder, Hon. Judge Charles V. Johnson (In Memoria)
        </h1>
        <div className="pastor-dedication-video">
          <iframe
            src="https://drive.google.com/file/d/1uy0egvDpJWokVpaq-mlfwlscoKU7J8S7/preview"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
      <div className="news-and-article">
        <br />
        <h1>News and Articles</h1>
        <News />
      </div>
    </div>
  );
}
function CollapsibleTrigger(
  arg0: string,
  arg1: string,
  viewDocuments: boolean
): string | React.ReactElement<any, string | React.JSXElementConstructor<any>> {
  throw new Error("Function not implemented.");
}
